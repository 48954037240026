/* modal and popover */
.vaf-certification-detail {
    /*&::part(content)  {
        width: 95vw !important;
        max-width: 700px !important;
        height: 90vh !important;
        max-height: 500px !important;
   }
   */
}

.vaf-certification-detail {
    &::part(content) {
        position: relative !important;
        margin: 0 auto !important;
        left: auto !important;
        top: auto !important;
        width: 90vw;
        max-width: 700px;
        max-height: 90vh;
    }
}

.vaf-fine-detail {
    &::part(content) {
        width: 90vw;
        max-width: 2000px;
        height: 90vh;
        max-height: 1000px;
    }
}

.vaf-payment-detail {
    &::part(content) {
        width: 90vw;
        max-width: 2000px;
        height: 90vh;
        max-height: 1000px;
    }
}

@media print {
    .vaf-fine-detail {
        &::part(content) {
            width: 100vw;
            max-width: 100vw;
            height: 100vh;
            max-height: 100vh;
        }
    }
}

.vaf-entity-registration {
    &::part(content) {
        width: 90vw !important;
        height: 90vh !important;
    }
}

.vaf-user-detail {
    &::part(content) {
        position: relative !important;
        margin: 0 auto !important;
        left: auto !important;
        top: auto !important;
        width: 90vw;
        max-width: 700px;
        max-height: 90vh;
    }
}

.vaf-fines-upload {
    &::part(content) {
        position: relative !important;
        margin: 0 auto !important;
        left: auto !important;
        top: auto !important;
        width: 90vw;
        max-width: 700px;
        max-height: 90vh;
    }
}

.vaf-entity-registration {
    &::part(content) {
        width: 90vw !important;
        max-width: 900px !important;
    }

}

/* mat progress bar */
.data-status-box .mat-progress-bar-fill:after {
    background-color: var(--ion-color-secondary) !important;
    height: 2px;
}

.data-status-box md-progress-linear .md-container {
    height: 2px !important;
}

.data-status-box md-progress-linear .md-container .md-bar {
    height: 2px !important;
}

/* page vaf-fine-create */
.vaf-fine-create .ngx-file-drop__content {
    height: auto !important;
    color: inherit !important;
}

.vaf-fine-create .ngx-file-drop__drop-zone--over {
    background-color: inherit;
    border: 3px dashed var(--ion-color-primary);
}

/* sort */
.vaf-search-results .table-box {
    width: 100%;
    padding: 0px !important;
}

.vaf-search-results table {
    width: 100%;
}

.vaf-search-results .mat-mdc-header-row,
.vaf-search-results [mat-mdc-header-row]{
    background: var(--ion-color-secondary);
    padding-left: 20px;
    padding-right: 130px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    height: auto !important;
}

.vaf-search-results .mat-mdc-header-cell,
.vaf-search-results [mat-mdc-header-cell] {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 56px;
    color: var(--ion-color-secondary-contrast);
    font-size: 14px;
    font-weight: normal;
    border: 0px;
}

.vaf-search-results .mat-header-button {
    padding: 5px 10px !important;
    height: auto !important;
    font-size: 13px !important;
    background: var(--ion-color-vaf-secondary-light) !important;
    color: var(--ion-color-secondary-contrast) !important;
    font-size: 13px !important;
    font-family: "AppFont" !important;
    text-transform: uppercase !important;
    margin: 5px !important;
    border-radius: 4px !important;
    box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%) !important;
}

.vaf-search-results .mat-header-button img {
    width: 15px;
    height: 15px;
    margin-right: 10px;
}

.vaf-search-results .mat-header-button-extract {
    position: absolute;
    top: 50% !important;
    transform: translate(0, -50%);
    right: 20px;
    margin: 0px !important;
}

.vaf-search-results th.mat-mdc-header-cell:first-of-type,
.vaf-search-results th[mat-mdc-header-cell]:first-of-type  {
    padding-left: 0px;
}

.vaf-search-results .mat-sort-header-arrow {
    color: var(--ion-color-secondary-contrast);
}

.vaf-search-results .mat-cell {
    border: 0px;
    padding: 0px !important;
    background: #eee;
}

.vaf-search-results .mat-row .mat-column-elementColumn {
    width: 100%;
}

.vaf-search-results .mat-row .mat-column-elementColumn ion-col {
    --padding: 0px;
    padding-left: 0px;
    padding-right: 0px;
}

/* fine search */
.vaf-fine-search {
    app-table-extraction ion-button {
        --ion-color-base: var(--ion-color-vaf-secondary-light) !important;
    }

    .date-time-input {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        input {
            width: calc(100% - 30px);
            border: 0px;
            outline: none;
        }

        mat-datepicker-toggle {
            button {
                width: 10px !important;
                height: 10px !important;
                line-height: inherit !important;
                margin-left: 0px !important;
                margin-right: 0px !important;
                outline: none !important;
            }
        }
    }
}

/* certification search */
.vaf-certification-search-page {
    app-table-extraction ion-button {
        --ion-color-base: var(--ion-color-vaf-secondary-light) !important;
    }

    .date-time-input {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        input {
            width: calc(100% - 30px);
            border: 0px;
            outline: none;
        }

        mat-datepicker-toggle {
            button {
                width: 10px !important;
                height: 10px !important;
                line-height: inherit !important;
                margin-left: 0px !important;
                margin-right: 0px !important;
                outline: none !important;
            }
        }
    }
}

/* payment search */
.vaf-payment-search-page {
    app-table-extraction ion-button {
        --ion-color-base: var(--ion-color-vaf-secondary-light) !important;
    }

    .date-time-input {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        input {
            width: calc(100% - 30px);
            border: 0px;
            outline: none;
        }

        mat-datepicker-toggle {
            button {
                width: 10px !important;
                height: 10px !important;
                line-height: inherit !important;
                margin-left: 0px !important;
                margin-right: 0px !important;
                outline: none !important;
            }
        }
    }
}

/* app-vaf-fine-card */
app-vaf-fine-card {
    width: 100%;
}

/* registration form */
.vaf-registration-exp .mat-expansion-panel-header-description,
.vaf-registration-exp .mat-expansion-indicator:after {
    color: #fff !important;
}

.vaf-registration-exp mat-expansion-panel-header .mat-content {
    /*background: var(--ion-color-primary) !important;
    height: 44px !important;
    margin-top: -20px !important;
    border-radius: 3px !important;
    padding: 10px !important;*/
    max-width: calc(100% - 30px);
}

/* pages title */
.page-title-card {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: 10px;
    background-color: var(--ion-color-secondary);
    color: var(--ion-color-secondary-contrast);
    padding: 20px 20px;
    margin: 20px;
}

.page-title-card img {
    width: 40px;
    height: 40px;
    margin-right: 10px;
}

.page-title-card .title-data {
    display: inline-flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    width: calc(100% - 50px);
}

.page-title-card .title-data div {
    font-weight: bold;
    font-size: 20px;
}

.page-title-card .title-data span {
    font-size: 14px;
}

/* input */
.empty-date-input {
    input[type="date"]::-webkit-datetime-edit {
        opacity: 0.3;
    }
}

/* icons */

@font-face {
    font-family: "view-and-fine";
    src: url("../../assets/view-and-fine_assets/font/view-and-fine.eot");
    src: url("../../assets/view-and-fine_assets/font/view-and-fine.eot?#iefix") format("embedded-opentype"),
        url("../../assets/view-and-fine_assets/font/view-and-fine.woff") format("woff"),
        url("../../assets/view-and-fine_assets/font/view-and-fine.ttf") format("truetype"),
        url("../../assets/view-and-fine_assets/font/view-and-fine.svg#view-and-fine") format("svg");
    font-weight: normal;
    font-style: normal;
}

[data-icon]:before {
    font-family: "view-and-fine" !important;
    content: attr(data-icon);
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    //speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
    font-family: "view-and-fine" !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    //speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/* selectable */
.violation-selectable {
    &::part(content) {
        width: 900px !important;
        max-width: 100% !important;
    }
}

.icon-gbp:before {
    content: "\61";
}

.icon-usd:before {
    content: "\62";
}

.icon-eur:before {
    content: "\63";
}

.icon-payments:before {
    content: "\266B";
}

.icon-doc:before {
    content: "\64";
}

.icon-car:before {
    content: "\66";
}

.icon-file:before {
    content: "\67";
}

.icon-position:before {
    content: "\68";
}

.icon-roud-warn:before {
    content: "\69";
}

.icon-speed:before {
    content: "\6a";
}

.icon-date:before {
    content: "\6b";
}

.icon-hourglass:before {
    content: "\65";
}