/* modals */
.postit-board-list-management {
    &::part(content)  {
        width: 90%;
        max-width: 900px;
        height: 90%;
        max-height: 900px;
    }
}
.postit-board-list-management-selectable {
    &::part(content)  {
        box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
        width: 90%;
        max-width: 900px;
        height: 90%;
        max-height: 900px;
    }
}
.postit-board-upload{
    &::part(content)  {
        width: 90%;
        max-width: 900px;
        height: 90%;
        max-height: 900px;
    }
}
/* postit-board-list-management-component */
.postit-board-list-management-component {
    .selectable-wrapper {
        ionic-selectable {
            .ionic-selectable-inner {
                align-items: center;
                height: 100%;
            }
            .ionic-selectable-value {
                padding: 0px;
                font-size: 14px;
                line-height: 14px;
            }
            .ionic-selectable-icon-inner {
                top: 0px;
            }
        }
    }
}
