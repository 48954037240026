/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import "@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

/* Projects globals */
/*@import "projects/evud/assets/evud-assets/scss/evud-global.scss";*/
@import "projects/odeva/global.scss";
@import "projects/ald/global.scss";
@import "projects/postit-board/global.scss";
@import "projects/view-and-fine/global.scss";

/* b */
b {
    color: #333;
}

/* label */
label {
    color: #333;
}

/* padding */
.standard-padding {
    padding-top: 10px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 10px;
}

.p-0 {
    padding: 0px !important;
}

.p-5px {
    padding: 5px;
}

.p-10px {
    padding: 10px;
}

.pt-10px {
    padding-top: 10px;
}

/* margin */
.mt-10px {
    margin-top: 10px;
}

.mb-10px {
    margin-bottom: 10px;
}

/* flex */
.flex-center-right {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
}

.flex-center-spacebetween {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}

.flex-center-center {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.flex-center-end {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
}

.flex-center-start {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
}

.inline-flex-center-center {
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

/* block */
.inline-block {
    display: inline-block;
}

/* alert s*/
.alert-service {
    .alert-wrapper {
        // .alert-head {
        //display: none !important;
        // }

        .alert-message {
            padding-top: 0px !important;
            padding-bottom: 0px !important;
            padding-left: 10px !important;
            padding-right: 10px !important;
            user-select: all !important;
        }

        textarea {
            resize: auto !important;
            white-space: pre-wrap;
        }
    }
}

.alert-wrapper {
    max-width: 600px !important;
    width: 90% !important;

    .alert-radio-button {
        min-height: 35px !important;
    }

    .alert-radio-label {
        padding-inline-start: 35px !important;
        padding-top: 5px !important;
        padding-bottom: 5px !important;
    }

    .alert-button-group {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-direction: row;

        .alert-button {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            background-color: var(--ion-color-primary);
            margin-top: 5px;
            border-radius: 4px;
            --box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
                0 1px 5px 0 rgba(0, 0, 0, 0.12);
        }

        .alert-button-warning {
            background-color: var(--ion-color-warning);
        }

        .alert-button-success {
            background-color: var(--ion-color-success);
        }

        .alert-button-danger {
            background-color: var(--ion-color-danger);
        }

        .alert-button-primary {
            background-color: var(--ion-color-primary);
        }

        .alert-button-secondary {
            background-color: var(--ion-color-secondary);
        }

        .alert-button-tertiary {
            background-color: var(--ion-color-tertiary);
        }

        .alert-button-role-cancel {
            background-color: var(--ion-color-tertiary);
        }
    }

    .alert-head {
        padding: 10px;
    }
}

.alert-service-title {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 10px;
    font-size: 18px;
    color: #333;
    border-bottom: 1px solid #f3f3f3;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 0px;
    padding-bottom: 8px;

    ion-icon {
        font-size: 25px;
    }

    position: sticky;
    top: 0;
    background: #fff;
}

.alert-service-message {
    padding-left: 0px;
    padding-right: 0px;
    white-space: pre-line;
}

.alert-input-group {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}

.alert-message {
    padding-bottom: 10px !important;

    ol,
    ul,
    dl {
        margin-bottom: 0px;
    }
}

.title-danger {
    color: var(--ion-color-danger);
}

.title-warning {
    color: var(--ion-color-warning);
}

.title-success {
    color: var(--ion-color-success);
}

.title-primary {
    color: var(--ion-color-primary);
}

.title-danger {
    color: var(--ion-color-danger);
}

.title-warning {
    color: var(--ion-color-warning);
}

.title-success {
    color: var(--ion-color-success);
}

.title-primary {
    color: var(--ion-color-primary);
}

.alert-checkbox-group,
.alert-radio-group {
    border: 0px !important;
}

/* ion-item */
ion-item {
    --highlight-color-focused: #999;
}

.no-p-right {
    --inner-padding-end: 0px;
}

/* input */

.input-field {
    //margin-bottom: 10px;
    --padding-start: 0px !important;
    --inner-padding-end: 0px !important;

    ion-label {
        margin-bottom: 10px !important;
        font-size: 18px !important;
        line-height: 20px !important;
    }

    .help-message {
        font-size: 12px;
        padding-left: 0px;
        color: #848484;
    }

    ion-input {
        border: 1px solid #cccccc;
        border-radius: 5px;
        border-bottom-left-radius: 0px;
        border-top-right-radius: 0px;
        --padding-start: 8px !important;
        --padding-end: 8px !important;
        --font-size: 14px;
        font-size: 14px;
        position: relative;
    }

    ion-input::before {
        content: '';
        position: absolute;
        width: 20px;
        height: 1px;
        top: -1px;
        left: auto;
        right: 0;
        background: linear-gradient(90deg, rgba(204, 204, 204, 1) 0%, rgba(255, 255, 255, 1) 42%);
    }

    ion-input::after {
        content: '';
        position: absolute;
        width: 20px;
        height: 1px;
        top: auto;
        left: 0px;
        bottom: -1px;
        background: linear-gradient(90deg, rgba(255, 255, 255, 1) 58%, rgba(204, 204, 204, 1) 100%);
    }

    input {
        height: 38px;
        max-height: 38px !important;
        font-size: 14px;
    }

    ion-checkbox {
        max-height: 38px !important;
        height: 38px !important;
        min-height: 38px !important;
        max-width: 38px !important;
        width: 38px !important;
        min-width: 38px !important;
        margin: 0px !important;
        padding: 8px !important;
    }

    ion-toggle {
        padding-left: 0px;
    }

    ion-range {
        padding: 0px;
        width: calc(100% - 10px);
        height: 38px;
        --height: 38px;
        min-height: 10px;
        --min-height: 38px;
        max-height: 38px;
        --max-height: 38px;
    }

    ion-select {
        height: 38px;
        min-height: 38px;
        border: 1px solid #cccccc;
        border-radius: 5px;
        border-bottom-left-radius: 0px;
        border-top-right-radius: 0px;
        --padding-start: 8px !important;
        --padding-end: 8px !important;
        overflow: visible;
        position: relative;


        &::part(icon) {
            top: 50%;
            transform: translateY(-50%);
        }

    }

    ion-select::before {
        content: '';
        position: absolute;
        width: 20px;
        height: 1px;
        top: -1px;
        left: auto;
        background-color: white;
        right: 0;
        background: linear-gradient(90deg, rgba(204, 204, 204, 1) 0%, rgba(255, 255, 255, 1) 42%);
    }

    ion-select::after {
        content: '';
        position: absolute;
        width: 20px;
        height: 1px;
        top: auto;
        background-color: white;
        left: 0px;
        bottom: -1px;
        background: linear-gradient(90deg, rgba(255, 255, 255, 1) 58%, rgba(204, 204, 204, 1) 100%);
    }




    ion-select:not(.legacy-select) {
        &::part(container) {
            width: 100% !important;
        }

        &::part(icon) {
            top: 50%;
            transform: translateY(-50%);
            position: absolute;
            right: 10px;
        }
    }

    ionic-selectable {
        height: 38px;
        border: 1px solid #cccccc;
        border-radius: 5px;
        border-bottom-left-radius: 0px;
        border-top-right-radius: 0px;
        padding: 0px 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;

        .ionic-selectable-inner {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
        }

        .ionic-selectable-value {
            span {
                display: flex;
                align-items: center;
                justify-content: flex-start;
            }
        }

        .ionic-selectable-icon-inner {
            top: 50%;
            transform: translateY(-50%);
        }
    }

    ionic-selectable::before {
        content: '';
        position: absolute;
        width: 20px;
        height: 1px;
        top: -1px;
        left: auto;
        background-color: white;
        right: 0;
        background: linear-gradient(90deg, rgba(204, 204, 204, 1) 0%, rgba(255, 255, 255, 1) 42%);
    }

    ionic-selectable::after {
        content: '';
        position: absolute;
        width: 20px;
        height: 1px;
        top: auto;
        background-color: white;
        left: 0px;
        bottom: -1px;
        background: linear-gradient(90deg, rgba(255, 255, 255, 1) 58%, rgba(204, 204, 204, 1) 100%);
    }


    ion-range::part(pin) {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        margin-top: 5px;
    }

    ion-input[type="file"] {
        --padding-top: 0px;
    }

    .date-time-input {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        max-width: 100%;
        border: 1px solid #cccccc;
        border-radius: 5px;
        border-bottom-left-radius: 0px;
        border-top-right-radius: 0px;
        height: 38px;
        padding: 0px 8px;
        position: relative;

        mat-date-range-input {
            display: inline-flex;
            align-items: center;
            justify-content: flex-start;
            width: calc(100% - 25px);

            .mat-date-range-input-container {
                align-items: center;
                justify-content: flex-start;
                display: flex;
                line-height: 16px;
                width: 100%;

                .mat-date-range-input-wrapper {}
            }

            .mdc-icon-button {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                border-radius: 0px;
            }
        }

        input {
            border: 0px;
            height: auto !important;
            background: transparent;
            outline: none;
        }
    }

    .date-time-input::before {
        content: '';
        position: absolute;
        width: 20px;
        height: 1px;
        top: -1px;
        left: auto;
        background-color: white;
        right: 0;
        background: linear-gradient(90deg, rgba(204, 204, 204, 1) 0%, rgba(255, 255, 255, 1) 42%);
    }

    .date-time-input::after {
        content: '';
        position: absolute;
        width: 20px;
        height: 1px;
        top: auto;
        background-color: white;
        left: 0px;
        bottom: -1px;
        background: linear-gradient(90deg, rgba(255, 255, 255, 1) 58%, rgba(204, 204, 204, 1) 100%);
    }


    .pssfe-radio-wrapper {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        width: 100%;
        padding-top: 5px;

        .pssfe-radio-wrapper-item {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 100%;

            input[type="radio"] {
                accent-color: var(--ion-color-primary);
                width: 15px;
                height: 15px;
            }

            span {
                width: calc(100% - 50px);
                padding-left: 10px;
                font-size: 14px;
            }
        }
    }

    .select-customers,
    .select-roles {
        border: 1px solid #cccccc;
        border-radius: 5px;
        border-bottom-left-radius: 0px;
        border-top-right-radius: 0px;
        width: 100%;
        height: 38px;
        text-align: left;
        padding: 0px 8px;
        font-size: 14px;
        position: relative;
    }

    .select-customers::before,
    .select-roles::before {
        content: '';
        position: absolute;
        width: 20px;
        height: 1px;
        top: -1px;
        left: auto;
        background-color: white;
        right: 0;
        background: linear-gradient(90deg, rgba(204, 204, 204, 1) 0%, rgba(255, 255, 255, 1) 42%);
    }

    .select-customers::after,
    .select-roles::after {
        content: '';
        position: absolute;
        width: 20px;
        height: 1px;
        top: auto;
        background-color: white;
        left: 0px;
        bottom: -1px;
        background: linear-gradient(90deg, rgba(255, 255, 255, 1) 58%, rgba(204, 204, 204, 1) 100%);
    }

}

.input-field:hover,
.input-field:active {
    ion-label {
        color: var(--ion-color-primary);
    }

    ion-input,
    ion-select,
    ionic-selectable,
    .date-time-input,
    .select-customers,
    .select-roles {
        animation-name: input-focus;
        animation-duration: 1s;
        animation-fill-mode: forwards;
    }
}

@keyframes input-focus {
    from {
        border-color: #cccccc;
    }

    to {
        border-color: var(--ion-color-primary);
    }
}

.input-field-icon {
    position: relative;

    ion-input {
        --padding-end: 50px !important;
    }

    ion-icon {
        position: absolute;
        z-index: 2;
        right: 25px;
        bottom: 9px;
    }

}

.item-interactive.ion-valid {
    --highlight-background: #999 !important;
}

.button-field {
    --ion-background-color: transparent;
}

.disabled-input {
    pointer-events: none;
}


.native-input.sc-ion-input-md:autofill,
.native-input.sc-ion-input-md:is(:-webkit-autofill, :autofill) {
    box-shadow: 0 0 0 50px white inset;
    text-fill-color: #333;
    -webkit-text-fill-color: #333 !important;
}

/*
input:autofill {
    height: 0px;
    box-shadow: 0 0 0 10px white inset;
    text-fill-color: #333;
}

input:autofill:focus {
    height: 0px;
    box-shadow: 0 0 0 10px white inset;
    text-fill-color: #333;
}*/

input[type="checkbox"] {
    position: relative;
    height: 15px;
    width: 15px;
}

input[type="checkbox"]:after {
    content: " ";
    background-color: #fff;
    color: #333;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    visibility: visible;
    height: 11px;
    width: 11px;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 2px;
    border-width: 2px;
    border-style: solid;
    border-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.51);
    cursor: pointer;
}

input[type="checkbox"]:checked:after {
    content: "\2713";
    background-color: var(--ion-color-primary);
    color: #fff;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    visibility: visible;
    height: 11px;
    width: 11px;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 2px;
    border-width: 2px;
    border-style: solid;
    border-color: var(--ion-color-primary);
}

input[type="checkbox"][disabled]:after {
    content: "";
    background-color: #e5e4e4;
    color: #fff;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    visibility: visible;
    height: 11px;
    width: 11px;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 2px;
    border-width: 2px;
    border-style: solid;
    border-color: #999;
    cursor: not-allowed;
}

input[type="checkbox"][disabled]:checked:after {
    content: "\2713";
    background-color: #e5e4e4;
    color: #fff;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    visibility: visible;
    height: 11px;
    width: 11px;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 2px;
    border-width: 2px;
    border-style: solid;
    border-color: #999;
    cursor: not-allowed;
}

input {
    background-color: #fff;
    color: #333;
}

input[disabled] {
    background-color: #ececec;
    color: #333;
}

button {
    color: #333;
}

button[disabled] {
    opacity: 0.2;
}

.check-disabled {
    pointer-events: none;
}

.check-disabled:after {
    cursor: default !important;
}

input::outer-spin-button,
input::inner-spin-button {
    appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}

input[type="date"]::calendar-picker-indicator,
input[type="datetime-local"]::calendar-picker-indicator {
    cursor: pointer;
}

input:autofill {
    box-shadow: 0 0 0px 1000px white inset;
}

::calendar-picker-indicator {
    margin-left: 0px;
}

@media (prefers-color-scheme: dark) {
    ::calendar-picker-indicator {
        filter: invert(1);
    }
}

::input-placeholder {
    opacity: 0.3 !important;
}

:-ms-input-placeholder {
    opacity: 0.3 !important;
}

::placeholder {
    opacity: 0.3 !important;
}

.input-date-empty {
    opacity: 0.3 !important;
}

.ionic-selectable-has-placeholder {
    .ionic-selectable-inner {
        .ionic-selectable-value {
            opacity: 0.3 !important;
        }
    }
}

input:autofill,
input:autofill:hover,
input:autofill:focus,
textarea:autofill,
textarea:autofill:hover,
textarea:autofill:focus,
select:autofill,
select:autofill:hover,
select:autofill:focus {
    border: 0px solid rgba(0, 0, 0, 0);
    text-fill-color: #333;
    box-shadow: 0 0 0px 1000px rgba(0, 0, 0, 0) inset;
    transition: background-color 5000s ease-in-out 0s;
}

.sc-ion-input-md-h:not(.legacy-input) {
    min-height: 38px;
}

:host(:not(.legacy-select)) {
    min-height: 38px;
}


/* webview modal*/
.webview-modal {
    &::part(content) {
        width: 100%;
        height: 100%;
    }
}

/* body */
body {
    color: #333;
    font-family: "AppFont";
    font-synthesis: none !important;
}

ion-content:not(:has(.modal-page-card)) {
    background: var(--ion-color-page-bg);
    --background: var(--ion-color-page-bg);
    /*--padding-top: 16px;
    --padding-bottom: 16px;
    --padding-start: 16px;
    --padding-end: 16px;*/
}

/* label required */
.box-required {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0px !important;
    --padding-start: 0px;
}

.label-required {
    color: #333 !important;
    font-size: 14px !important;

    span {
        color: #e14d43;
    }
}

/* validation message */
.validation-message {
    font-size: 14px;
    color: #999;
    padding-bottom: 10px;
}

/* width */
.width-100 {
    width: 100%;
}

/* font */
.fs-20px {
    font-size: 20px;
}

/* ion-col */
ion-col {
    width: 100%;
}

/* cards */
.page-card {
    border-radius: 5px;
    -webkit-box-shadow: 0 1px 2px rgba(56, 65, 74, .15);
    box-shadow: 0 1px 2px rgba(56, 65, 74, .15);
    margin-bottom: 30px;
    border: 1px solid #e9ebec;

    ion-card-header {
        background-color: var(--ion-color-card-bg);
        padding: 16px;
        padding-left: 20px;
        border-bottom: 1px solid #e9ebec;
        position: relative;
    }

    ion-card-header::before {
        content: '';
        position: absolute;
        height: 85%;
        width: 6px;
        top: 50%;
        left: 6px;
        transform: translateY(-50%);
        border-radius: 3px;
        background: limegreen;
    }

    ion-card-title {
        color: var(--ion-color-card-contrast);
        font-size: 16px;
        font-weight: 500;

        ion-grid {
            padding: 0px;
        }

        ion-row {
            padding: 0px;
        }

        ion-col {
            padding: 0px;
        }
    }

    .ion-card-title-small {
        color: var(--ion-color-primary-contrast);
        font-size: 16px;

        span {
            font-weight: normal;
        }
    }

    ion-card-content {
        padding: 10px 0px;
    }

    ion-list {
        padding-bottom: 0px;

        ion-item:hover {
            cursor: pointer;
        }
    }

    hr {
        background-color: #eee;
        margin-left: 5px;
        margin-right: 5px;
    }

    ion-col {
        padding-top: 0px;
        padding-bottom: 5px;
        padding-left: 15px;
        padding-right: 15px;
    }

    ion-grid {
        padding: 0px;
    }
}

.modal-page-card {
    border-radius: 0px;
    margin: 0px;
    box-shadow: none;

    ion-card-header {
        background-color: var(--ion-color-primary);
        padding-left: 8px;
        padding-top: 10px;
        padding-right: 8px;
        padding-bottom: 10px;
    }

    ion-card-title {
        color: var(--ion-color-primary-contrast);

        ion-grid {
            padding: 0px;
        }

        ion-row {
            padding: 0px;
        }

        ion-col {
            padding: 0px;
        }
    }

    .ion-card-title-small {
        color: var(--ion-color-primary-contrast);
        font-size: 16px;

        span {
            font-weight: normal;
        }
    }

    ion-card-content {
        padding: 0;
    }

    ion-list {
        padding-bottom: 0px;

        ion-item:hover {
            cursor: pointer;
        }
    }

    hr {
        background-color: #eee;
        margin-left: 5px;
        margin-right: 5px;
    }

    ion-col {
        padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 15px;
        padding-right: 15px;
    }

    ion-grid {
        padding: 0px;
    }
}

.group-header {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    padding: 0px 15px;
    font-size: 16px;
    margin-top: 10px;


    span {
        color: var(--ion-color-primary);
        width: 100% !important; //important for toastr override
        border-bottom: 1px solid rgba(var(--ion-color-primary-rgb), 0.3);
    }

    div {
        width: 100%;
        padding-left: 15px;
        color: #333;
        background-color: #fff;
    }
}

.header-spacebetween {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .header-buttons {
        display: inline-flex;
        align-items: center;
        justify-content: flex-end;

        img {
            width: 25px;
            margin-left: 10px;
            cursor: pointer;
            filter: brightness(0) saturate(100%) invert(20%) sepia(9%) saturate(6003%) hue-rotate(191deg) brightness(93%) contrast(92%);
        }

        ion-icon {
            margin-left: 10px;
            font-size: 25px;
            cursor: pointer;
        }

        ion-button {
            margin: 0;
            margin-left: 10px;

            ion-icon {
                margin-left: 0px;
                font-size: 16px;
            }
        }

        .ion-button-color-primary {
            --ion-color-contrast: var(--ion-color-white-contrast) !important;
            --ion-color-base: var(--ion-color-white) !important;
        }

        .ion-button-color-secondary {
            --ion-color-contrast: var(--ion-color-secondary-contrast) !important;
            --ion-color-base: var(--ion-color-secondary) !important;
        }

        .ion-button-color-tertiary {
            --ion-color-contrast: var(--ion-color-tertiary-contrast) !important;
            --ion-color-base: var(--ion-color-tertiary) !important;
        }

        .ion-button-color-success {
            --ion-color-contrast: var(--ion-color-success-contrast) !important;
            --ion-color-base: var(--ion-color-success) !important;
        }

        .ion-button-color-warning {
            --ion-color-contrast: var(--ion-color-warning-contrast) !important;
            --ion-color-base: var(--ion-color-warning) !important;
        }

        .ion-button-color-danger {
            --ion-color-contrast: var(--ion-color-danger-contrast) !important;
            --ion-color-base: var(--ion-color-danger) !important;
        }
    }
}

/* expansion panel */
.data-integration-detail-page .mat-expansion-panel-body {
    padding: 10px !important;
}

.data-integration-detail-page .mat-expansion-indicator:after {
    color: #fff;
}

/* swiper-container */
.plate-search-page swiper-container .swiper-pagination {
    top: 0 !important;
    bottom: auto !important;
    height: 30px;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    .swiper-pagination-bullet {
        height: 10px;
        width: 10px;
        opacity: 1;
    }

    .swiper-pagination-bullet-active {
        opacity: 1;
    }
}

.data-integration-detail-page swiper-container .swiper-pagination {
    top: 0 !important;
    bottom: auto !important;
    height: 30px;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    .swiper-pagination-bullet {
        height: 10px;
        width: 10px;
        opacity: 1;
    }

    .swiper-pagination-bullet-active {
        opacity: 1;
    }
}

/* ion-card-title */
ion-card-title {
    font-size: 20px;
}

/* mat table */
.mat-sort-header-button {
    font-size: 14px !important;
}

.mat-mdc-header-cell-resizer {
    display: inline-block;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 3px;
    background-color: #999;
    cursor: ew-resize;
}

/* scroll pages */
.data-integration-detail-page {
    --overflow: scroll;
}

/* ion-select */
ion-select {
    height: 30px;
}

ion-select-popover {
    padding-left: 5px !important;
    padding-right: 5px !important;
}

ion-item.select-interface-option:hover {
    background: rgba(var(--ion-color-primary-rgb), 0.2);
}

.alert-radio-group.sc-ion-alert-md::-webkit-scrollbar,
.alert-checkbox-group.sc-ion-alert-md::-webkit-scrollbar,
.alert-message.sc-ion-alert-md::-webkit-scrollbar {
    width: 1em !important;
    display: block !important;
}

.alert-radio-group.sc-ion-alert-md::-webkit-scrollbar-track,
.alert-checkbox-group.sc-ion-alert-md::-webkit-scrollbar-track,
.alert-message.sc-ion-alert-md::-webkit-scrollbar-track {
    background-color: #f1f1f1;
}

.alert-radio-group.sc-ion-alert-md::-webkit-scrollbar-thumb,
.alert-checkbox-group.sc-ion-alert-md::-webkit-scrollbar-thumb,
.alert-message.sc-ion-alert-md::-webkit-scrollbar-thumb {
    background-color: #c1c1c1 !important;
    outline: 1px solid #f1f1f1 !important;
}

.modify-template-button {
    ion-select {
        --placeholder-opacity: 1 !important;

        .select-text {
            opacity: 1 !important;
            color: #333;
        }

        .select-icon {
            opacity: 1 !important;
            color: #333;
        }
    }
}

.ion-select-modal-disabled {
    pointer-events: none;
    opacity: 0.6;
}

/* popover modal */

.popover-content.sc-ion-popover-ios {
    border-radius: 0% !important;
}

.right-menu-popover {
    &::part(content) {
        left: auto !important;
        top: 60px !important;
        right: 0 !important;
        bottom: auto !important;
        width: 90vw;
        max-width: 400px;
        max-height: 90vh;
        border-radius: 0px;
    }
}

.admin-user-detail-popover {
    &::part(content) {
        position: relative !important;
        margin: 0 auto !important;
        left: auto !important;
        top: auto !important;
        width: 90vw !important;
        max-width: 900px !important;
        max-height: 90vh !important;
    }
}

.admin-user-detail-popover {
    &::part(content) {
        position: relative !important;
        margin: 0 auto !important;
        left: auto !important;
        top: auto !important;
        width: 90vw;
        max-width: 900px;
        max-height: 90vh;
    }
}

.admin-role-detail-popover {
    &::part(content) {
        position: relative !important;
        margin: 0 auto !important;
        left: auto !important;
        top: auto !important;
        width: 90vw !important;
        max-width: 900px !important;
        max-height: 90vh;
    }
}

.selectable-tree-popover {
    &::part(content) {
        position: relative !important;
        margin: 0 auto !important;
        left: auto !important;
        top: auto !important;
        width: 90vw;
        max-width: 900px;
        max-height: 90vh;
    }
}

.selectable-modules {
    &::part(content) {
        position: relative !important;
        margin: 0 auto !important;
        left: auto !important;
        top: auto !important;
        width: 90vw;
        max-width: 900px;
        max-height: 90vh;
    }
}

.service-access-configuration-template {
    &::part(content) {
        position: relative !important;
        margin: 0 auto !important;
        left: auto !important;
        top: auto !important;
        width: 90vw;
        max-width: 900px;
        max-height: 90vh;
    }
}

.admin-message-detail-popover {
    &::part(content) {
        position: relative !important;
        margin: 0 auto !important;
        left: auto !important;
        top: auto !important;
        width: 90vw !important;
        max-width: 900px !important;
        max-height: 90vh;
    }
}

.message-detail-popover {
    &::part(content) {
        position: relative !important;
        margin: 0 auto !important;
        left: auto !important;
        top: auto !important;
        width: 90vw !important;
        max-width: 600px !important;
        max-height: 90vh;
    }
}

.recipients-list-popover {
    &::part(content) {
        position: relative !important;
        margin: 0 auto !important;
        left: auto !important;
        top: auto !important;
        width: 90vw !important;
        max-width: 600px !important;
        max-height: 90vh;
    }
}

.admin-user-massive-create-settings {
    &::part(content) {
        position: relative !important;
        margin: 0 auto !important;
        left: auto !important;
        top: auto !important;
        width: 90vw;
        max-width: 900px;
        max-height: 90vh;
    }
}

.loading-log-popover {
    &::part(content) {
        position: relative !important;
        margin: 0 auto !important;
        left: auto !important;
        top: auto !important;
        width: 90vw;
        max-width: 500px;
    }
}

.news-detail-popover {
    &::part(content) {
        width: 95vw !important;
        max-width: 900px !important;
        height: 90vh !important;
    }
}

.pending-request-popover {
    &::part(content) {
        width: 350px;
        max-width: 350px;
    }
}

.admin-restgenerator-table {
    &::part(content) {
        position: relative !important;
        margin: 0 auto !important;
        left: auto !important;
        top: auto !important;
        width: 90vw;
        max-width: 900px;
        max-height: 90vh;
    }
}

.table-popup-input {
    &::part(content) {
        position: relative !important;
        margin: 0 auto !important;
        left: auto !important;
        top: auto !important;
        width: 90vw;
        max-width: 500px;
        max-height: 90vh;
    }
}

.table-popup-input-popover {
    &::part(content) {
        min-width: 270px;
    }
}

.pssfe-dropdown-prompt {
    &::part(content) {
        width: 95vw !important;
        max-width: 700px !important;
        max-height: 500px !important;
        height: 90vh !important;
    }
}

.crp-configuration-modal {
    &::part(content) {
        width: 95vw !important;
        max-width: 95vw !important;
        height: 90vh !important;
    }
}

.crp-new-configuration-modal {
    &::part(content) {
        width: 95vw !important;
        max-width: 450px !important;
        height: 60vh !important;
        max-height: 260px !important;
    }
}

.crp-combo-edit {
    &::part(content) {
        position: relative !important;
        margin: 0 auto !important;
        left: auto !important;
        top: auto !important;
        width: 90vw;
        max-width: 500px;
        max-height: 90vh;
    }
}

.search-state-history-prompt {
    &::part(content) {
        width: 95vw !important;
        max-width: 500px !important;
        max-height: 90vh !important;
    }
}

.pin-popover-numeric {
    &::part(content) {
        position: relative !important;
        margin: 0 auto !important;
        left: auto !important;
        top: auto !important;
        width: 90vw !important;
        max-width: 225px !important;
    }
}

.pin-popover-alphanumeric {
    &::part(content) {
        position: relative !important;
        margin: 0 auto !important;
        left: auto !important;
        top: auto !important;
        width: 98vw !important;
        max-width: 385px !important;
        max-height: 80px !important;
        overflow-y: hidden;
    }
}

.dcrud-form-modal-large {
    &::part(content) {
        width: 95vw !important;
        height: 90vh !important;
    }
}

.dcrud-form-modal-medium {
    &::part(content) {
        width: 95vw !important;
        height: 90vh !important;
        max-width: 900px !important;
        max-height: 500px !important;
    }
}

.dcrud-form-modal-small {
    &::part(content) {
        width: 95vw !important;
        height: 90vh !important;
        max-width: 400px !important;
        max-height: 350px !important;
    }
}

.tables-prompt-component {
    &::part(content) {
        width: 95vw !important;
        height: 90vh !important;
    }
}

/* ion-selectable */
ionic-selectable-modal {
    ion-header {
        ion-toolbar {
            --height: 38px !important;
            --max-height: 38px !important;
            --min-height: 38px !important;

            ion-title {
                padding-left: 10px !important;
            }

            ion-searchbar {
                .searchbar-input-container {
                    ion-icon {
                        left: 0px !important;
                    }

                    input {
                        padding-left: 30px !important;
                        box-shadow: none !important;
                    }
                }
            }
        }
    }

    ion-content {
        --background: var(--ion-color-card-bg);

        ion-list {
            padding: 10px !important;
        }
    }
}

.selectable-disabled {
    pointer-events: none;
    opacity: 0.32;
}

.selectable-readonly {
    pointer-events: none;
}

ionic-selectable {
    height: 38px;
}

.ionic-selectable-value {
    span {
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: block;
        word-break: break-all;

        .ionic-selectable-value-item {
            display: inline-block !important;
            margin-bottom: 0px !important;
        }

        .ionic-selectable-value-item:after {
            content: ",";
            margin-right: 5px;
        }

        .ionic-selectable-value-item:last-child:after {
            content: "" !important;
        }
    }
}

.ionic-selectable-has-placeholder .ionic-selectable-value-item {
    color: #333 !important;
}

.ionic-selectable-item-is-disabled {
    opacity: 1 !important;
}

.ionic-selectable-empty {
    .ionic-selectable-inner {
        .ionic-selectable-value {
            opacity: 0.6;
        }
    }
}

/* modal-popover header*/
.modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0px;
    border-bottom: 0px;
}

.modal-title {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    color: #fff;
    font-size: 18px;
    font-weight: bold;
}

.modal-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 18px;
    cursor: pointer;
}

/* ion-card-title */
ion-card-title {
    font-size: 20px;
}

/* mat table */
.mat-sort-header-button {
    font-size: 14px !important;
}

/* scroll pages */
.plate-search-page,
.nominative-search-page,
.nominative-detail-page,
.history-search-detail-page,
.custom-reports-search-page,
.custom-reports-page {
    --overflow: scroll;
}

/* ion-select */
ion-select-popover {
    padding-left: 5px !important;
    padding-right: 5px !important;
}

.alert-checkbox-button-disabled {
    opacity: 0.8 !important;
}

/* form */
form {
    width: 100%;
}

/* mat expansion table*/
.admin-role-detail-popover .last-item .mat-expansion-indicator:after {
    display: none;
}

.admin-role-detail-popover .last-item .mat-expansion-panel-header {
    padding-left: 13px;
}

.admin-role-detail-popover .last-item .mat-expansion-header {
    cursor: default;
    margin-left: 8px !important;
}

.admin-role-detail-popover .last-item .mat-expansion-panel-body {
    padding: 0px !important;
}

.admin-user-detail-popover .mat-expansion-panel-body {
    padding-top: 0px !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
    padding-bottom: 0px !important;
}

/* mat stepepr */
.decurtazione-punti-page {
    .mat-step-icon-selected {
        transform: scale(1.1);
        transition-duration: 0.5s;
    }

    .mat-step-label-selected {
        color: #3f51b5 !important;
        font-size: 16px;
    }

    .image-disabled {
        #cdk-step-label-0-1 {
            pointer-events: none !important;
        }
    }

    .review-disabled {
        #cdk-step-label-0-2 {
            pointer-events: none !important;
        }

        mat-step-header {
            //pointer-events: none !important;
        }
    }

    .mat-horizontal-content-container {
        padding-left: 10px !important;
        padding-right: 10px !important;
    }
}

/* text */
.uppercase {
    text-transform: uppercase;
}

/* app-help */
app-help {
    display: inline-block;
}

/* cursor */
.input-cursor-pointer {
    input {
        cursor: pointer !important;
    }
}

.clickable {
    cursor: pointer;
}

/* NGX-File-Drop */
.dropzone-container {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #0782d0;
    width: 100%;
    height: auto;
    max-height: 100px;
    min-width: 100px;
    border: 1px dotted #0782d0;
    border-radius: 0.25em;

    #dropzone-filename {
        font-size: 15px;
    }
}

.dropzone-container-request {
    @extend .dropzone-container;
    font-size: 15px;
    padding: 5px;
}

.dropzone-container-history {
    @extend .dropzone-container-request;
    max-height: 60px;
    min-width: 120px;

    >div {
        max-height: 60px;
    }
}

/* mat-table */
.mat-mdc-header-cell {
    font-weight: bold !important;
    color: #333 !important;
}

.mat-header-center {
    .mat-sort-header-container {
        justify-content: center;
    }
}

.mat-column-action {
    width: 50px !important;
    min-width: 50px !important;
    text-align: center;
    cursor: pointer;

    img {
        width: 21px;
    }

    ion-icon {
        font-size: 16px;
    }
}

.mat-column-action:hover {
    img:hover {
        transform: scale(1.2);
    }

    ion-icon:hover {
        transform: scale(1.1);
    }
}

/* cursor */
.cursor-pointer {
    cursor: pointer !important;

    ion-input {
        cursor: pointer !important;

        input {
            cursor: pointer !important;
        }
    }
}

/* button-box */
.button-box {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    padding-top: 20px;
    width: 100%;

    .button-left {
        margin-right: 0px;
        --border-radius: 4px 0px 0px 4px;
        --box-shadow: -1px 0px 1px 0px rgba(0, 0, 0, 0.2);
        --padding-end: 5px;
    }

    .button-right {
        margin-left: 0px;
        --border-radius: 0px 4px 4px 0px;
        --box-shadow: 1px 0px 1px 0px rgba(0, 0, 0, 0.2);
        --padding-start: 5px;
    }
}

@media only screen and (max-width: 576px) {
    .button-box {
        ion-button {
            width: 100%;
        }

        .button-left {
            margin-right: 2px;
            --border-radius: 4px 4px 4px 4px;
        }

        .button-right {
            margin-left: 2px;
            --border-radius: 4px 4px 4px 4px;
        }
    }

    .login-left {
        min-width: 20% !important;
        width: 20% !important;
        max-width: 20% !important;

        .login-left-wrapper {
            padding: 5px !important;

            .login-message {
                display: none !important;
            }
        }
    }

    .login-right {
        padding: 10px !important;
    }
}

/* language */
.language-select {
    ion-radio {
        display: none;
    }
}

/* service-access-configuration */
.service-access-configuration {
    .mat-tab-labels {
        background-color: #e2e2e2;
    }

    .mat-tab-label {
        opacity: 1;
    }

    .mat-tab-label-active {
        .mat-tab-label-content {
            background-color: #fff;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
        }
    }

    .mat-tab-label-content {
        padding-top: 5px;
        padding-left: 10px;
        padding-right: 10px;
        padding-bottom: 5px;
        opacity: 1;
    }

    .mat-tab-header {
        border-bottom: 0px;
    }

    .mat-tab-label {
        height: auto;
        min-width: auto;
        padding: 10px;
        padding-bottom: 0px;
    }

    mat-ink-bar {
        visibility: hidden !important;
    }
}

/* admin-role-detail */
.admin-role-detail-popover {
    mat-tab-header {
        height: 0px;
    }

    .tab-show {
        mat-tab-header {
            height: auto;
        }
    }

    .mat-tab-label-active {
        opacity: 1 !important;

        .mat-tab-label-content {
            opacity: 1 !important;
        }
    }
}

.admin-user-detail-popover {
    mat-tab-header {
        height: 0px;
    }

    .tab-show {
        mat-tab-header {
            height: auto;
        }
    }
}

/* imma ext removable*/
.localization-popover {
    &::part(content) {
        position: relative !important;
        margin: 0 auto !important;
        left: auto !important;
        top: auto !important;
        width: 90vw !important;
        max-width: 900px !important;
        max-height: 90vh;
    }
}

.monitoring-area-detail {
    &::part(content) {
        position: relative !important;
        margin: 0 auto !important;
        left: auto !important;
        top: auto !important;
        width: 90vw !important;
        max-width: 900px !important;
        max-height: 90vh;
    }
}

.monitoring-vehicle-detail {
    &::part(content) {
        position: relative !important;
        margin: 0 auto !important;
        left: auto !important;
        top: auto !important;
        width: 90vw !important;
        max-width: 900px !important;
        max-height: 90vh;
    }
}

.routes-history-search-popover {
    &::part(content) {
        position: relative !important;
        margin: 0 auto !important;
        left: auto !important;
        top: auto !important;
        width: 90vw !important;
        max-width: 90vw !important;
        max-height: 90vh;
    }
}

.reject-registration {
    &::part(content) {
        position: relative !important;
        margin: 0 auto !important;
        left: auto !important;
        top: auto !important;
        width: 90vw !important;
        max-width: 900px !important;
        max-height: 90vh;
    }
}

/* tooltip */
.my-custom-tooltip {
    max-width: unset !important;
    white-space: pre-line !important;
    //overflow-y: scroll !important;
    //max-height: 100px !important;
}

.json-custom-tooltip {
    min-width: 300px !important;
    max-width: unset !important;
    white-space: pre-line !important;
    font-size: 14px;
}

/* mat datepicker */
mat-datepicker-toggle {
    button {
        padding: 0px !important;
        height: 30px !important;
        width: 30px !important;
    }

    span {
        display: none !important;
    }

    svg {
        width: 20px !important;
        height: 20px !important;
    }

    /*button {
        width: 20px !important;
        height: 20px !important;
        line-height: inherit !important;
        margin-left: 3px !important;
        margin-right: 3px !important;

        .mat-button-wrapper {
            line-height: inherit !important;
        }
    }

    .mat-datepicker-toggle-default-icon {
        width: 20px !important;
        height: 20px !important;
        color: #333;
    }*/
}

/* toast */
.warning-toast {
    background-color: var(--ion-color-warning) !important;
    box-shadow: none !important;
}

.success-toast {
    background-color: var(--ion-color-success) !important;
    box-shadow: none !important;
}

.error-toast {
    background-color: var(--ion-color-danger) !important;
    box-shadow: none !important;
}

/* menu */
ion-menu {
    border-right-color: transparent;
    border-right: 0px;
    --ion-background-color: var(--ion-color-primary);

    &::part(container) {
        max-width: 250px;
    }

    ion-content {
        min-width: 250px !important;
        max-width: 250px !important;
        width: 250px !important;
    }

    ion-toolbar {
        --background: var(--ion-color-primary);
    }

    ion-item {
        max-width: 250px !important;
        color: var(--ion-color-primary-contrast);
        border-radius: 0px !important;
        --min-height: auto;

        .menu-item-inner {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            color: var(--ion-color-primary-contrast);
            width: 100%;
            padding-bottom: 15px;
            margin-top: 15px;
            margin-bottom: 0px;

            span {
                display: inline-flex;
                align-items: center;
                justify-content: space-between;
                //width: calc(100% - 30px); tolto 30/12/2024
                width: 100%;
            }

            ion-icon {
                //color: #636261;
                margin-right: 10px;
                font-size: 16px;
            }

            .menu-icon {
                //filter: invert(85%) sepia(5%) saturate(782%) hue-rotate(196deg) brightness(117%) contrast(100%);
            }

            .input-wrapper {
                border-bottom: 1px solid #636261;
            }

            i{
                font-size: 15px;
                margin-right: 10px;
            }
        }
    }

    ion-item:hover {
        //color: #333 !important;
        background-color: rgba(255, 255, 255, 0.1);
        --ion-background-color: rgba(255, 255, 255, 0.1);
        --background: rgba(255, 255, 255, 0.1) !important;

        .menu-item-inner {
            //border-bottom: 1px solid transparent;
        }

        ion-icon {
            //color: #333 !important;
        }

        .menu-icon {
            //filter: invert(97%) sepia(5%) saturate(782%) hue-rotate(196deg) brightness(117%) contrast(100%);
        }
    }

    ion-item.selected {
        color: #333 !important;
        background-color: rgba(255, 255, 255, 0.1);
        --ion-background-color: rgba(255, 255, 255, 0.1);
        --background: rgba(255, 255, 255, 0.1) !important;

        position: relative;

        .menu-item-inner {
            border-bottom: 1px solid transparent;
        }

        ion-icon {
            //color: #333 !important;
        }

        .menu-icon {
            //filter: invert(97%) sepia(5%) saturate(782%) hue-rotate(196deg) brightness(117%) contrast(100%);
        }
    }

    ion-item.selected::before {
        content: '';
        position: absolute;
        height: 80%;
        width: 3px;
        top: 50%;
        left: 5px;
        transform: translateY(-50%);
        border-radius: 3px;
        background: limegreen;
    }

    ion-list {
        padding-top: 0px !important;
        padding-left: 10px;
        padding-right: 10px;
        overflow-y: auto;

        ion-list-header {
            color: #fff !important;
        }
    }

    .item-node {
        cursor: pointer;

        ion-icon {
            //color: #636261;
        }
    }

    .item-node:hover {
        //--ion-background-color: rgba(167, 165, 173, 0.04);
    }

    .sub-item {
        padding-left: 15px;
    }

    .menu-icon {
        width: 18px;
        height: 18px;
        margin-right: 10px;
    }

    .circle-selected {
        transform: rotate(90deg);
    }

    .cursor-pointer {
        cursor: pointer;
    }

    .hidden-container {
        height: 0px;
    }

    .parent-hidden {
        padding-left: 0px;
    }
}

@media only screen and (min-width: 1279px) {
    ion-menu {
        min-width: 250px !important;
        max-width: 250px !important;
        width: 250px !important;
    }
}

/* app table component */
.app-table-container {
    th {
        height: var(--mat-table-header-container-height, 56px);
        padding: 0px;
    }

    tr {
        font-family: 'AppFont' !important;
    }

    input[type="checkbox"] {
        height: 15px !important;
        width: 15px !important;
    }

    input[type="checkbox"]:after {
        height: 15px !important;
        width: 15px !important;
    }

    .mat-mdc-paginator-navigation-next,
    .mat-mdc-paginator-navigation-previous {
        outline: none;
        width: 35px;
        height: 35px;
        border-radius: 5px;
        background: transparent;
        border: 1px solid #e9ebec;
        padding: 0px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
    }

    .mat-mdc-paginator-navigation-next[disabled],
    .mat-mdc-paginator-navigation-previous[disabled] {
        opacity: 0.4;
    }

    .mat-mdc-paginator-navigation-previous {
        margin-right: 15px;
    }

    .mat-mdc-button-persistent-ripple {
        border-radius: 5px !important;
    }

    .mat-mdc-paginator-icon {
        fill: var(--ion-color-primary);
    }

    .mat-mdc-icon-button[disabled] .mat-mdc-paginator-icon {
        fill: var(--ion-color-primary);
    }

    .mat-mdc-paginator-page-size {
        mat-select {
            height: 35px;
            background: transparent;
            border: 1px solid #e9ebec;
            color: var(--ion-color-primary);
            padding: 0px 8px;
            border-radius: 5px;
            display: inline-flex;
            align-items: center;
            justify-content: center;

            svg {
                fill: var(--ion-color-primary);
            }
        }
    }

    .table-container {
        overflow-x: auto;
        overflow-y: auto;
        width: 100%;
    }

    .column-resizer {
        display: inline-block;
        width: 2px;
        top: 8px;
        bottom: 8px;
        right: 0;
        position: absolute;
        background-color: rgba(216, 216, 216, 1);
        cursor: ew-resize;
    }

    .column-resizer:hover {
        width: 5px;
        background-color: rgba(216, 216, 216, 1);
    }

    .unresazibleColumn {
        cursor: default !important;
    }

    .unresazibleColumn:hover {
        width: 2px !important;
        cursor: default !important;
    }

    .mat-sort-header-container {
        padding-right: 15px !important;
        padding-left: 5px !important;
        height: 100%;

        .mat-sort-header-content {
            width: 100%;
        }

        .header-cell-inner {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }

    .mat-mdc-header-cell {
        height: 56px;
        font-size: 14px;
    }

    .equal-padding {
        padding-right: 5px !important;
        padding-left: 5px !important;
    }

    .mat-mdc-cell {
        /* box-shadow: inset -1px 0px 0px 0px rgb(216, 216, 216);
        box-shadow: inset -1px 0px 0px 0px rgb(216, 216, 216);*/
        padding-right: 15px !important;
        padding-left: 5px !important;
    }

    th.mat-mdc-header-cell:first-of-type,
    td.mat-mdc-cell:first-of-type,
    td.mat-footer-cell:first-of-type,
    th.mat-mdc-header-cell:last-of-type,
    td.mat-mdc-cell:last-of-type,
    td.mat-footer-cell:last-of-type {
        padding-left: 0px;
        padding-right: 0px;
    }

    .table-footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 100%;

        .table-footer-buttons {
            display: inline-flex;
            align-items: center;
            justify-content: flex-start;
            width: 100px;
            padding-left: 15px;
            padding-right: 15px;
        }

        .table-footer-paginator {
            display: inline-flex;
            align-items: center;
            justify-content: flex-end;
            max-width: calc(100% - 100px);
        }
    }

    .table-footer-mobile {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        width: 100%;

        .table-footer-buttons {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            margin-bottom: 20px;
            width: 100%;

            ion-button {
                width: 100%;
            }

            app-table-extraction {
                width: 100%;
            }
        }
    }

    .column-hidden {
        width: 0px !important;
        max-width: 0px !important;
        min-width: 0px !important;
        padding: 0px !important;
        margin: 0px !important;
        white-space: nowrap !important;
        overflow: hidden !important;
        border: 0px solid transparent !important;
    }

    .detail-icon {
        display: inline-block;
    }

    .detail-column {
        img {
            width: 21px;
            text-align: center;
            cursor: pointer;
        }
    }

    .show-grid {

        .mat-mdc-cell,
        .mat-mdc-header-cell {
            box-shadow: inset -1px 0px 0px 0px rgb(216, 216, 216);
            box-shadow: inset -1px 0px 0px 0px rgb(216, 216, 216);
        }
    }

    .comparison-box {
        display: inline-flex;
        align-items: center;
        justify-content: flex-start;

        ion-icon {
            font-size: 12px;
            padding-left: 10px;
        }

        .comparison-done {
            color: var(--ion-color-primary-contrast);
            background-color: var(--ion-color-primary);
        }
    }

    .mdc-text-field--outlined {
        --mdc-outlined-text-field-outline-width: 0px;
    }
}

.mdc-text-field--outlined {
    --mdc-outlined-text-field-outline-width: 0px !important;
}

.table-tooltip {
    max-width: unset !important;
    white-space: pre-line !important;
    font-size: 14px;
}

.table-mobile {
    .input-boolean {
        justify-content: flex-end !important;
    }

    .table-td {
        justify-content: flex-end !important;

        .input-icona-wrapper {
            justify-content: flex-end !important;
        }
    }

    .detail-column {
        img {
            width: 19px;
        }
    }

    app-table-td {
        display: flex !important;
        width: 100%;
    }

    table {
        .mat-mdc-cell {
            padding-right: 0px !important;
            padding-left: 0px !important;
        }

        .mat-mdc-header-cell {
            height: auto !important;
        }

        .mat-mdc-header-row,
        [mat-mdc-header-row] {
            height: auto !important;
        }
    }
}

/* gauge */
app-gauge {
    width: 100%;
}

/* global loader */
.global-loader-container {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99999999;
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.6);

    .global-loader-center {
        display: inline-flex;
        align-items: center;
        justify-content: center;

        .global-loader-center-inner {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            .loader-folding-cube {
                width: 55px;
                height: 55px;
                transform: rotateZ(45deg);
                transform: rotateZ(45deg);
            }

            .loader-folding-cube .loader-cube {
                float: left;
                width: 50%;
                height: 50%;
                position: relative;
                transform: scale(1.1);
                -ms-transform: scale(1.1);
                transform: scale(1.1);
            }

            .loader-folding-cube .loader-cube:before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                animation: loader-foldCubeAngle 2.4s infinite linear both;
                animation: loader-foldCubeAngle 2.4s infinite linear both;
                transform-origin: 100% 100%;
                -ms-transform-origin: 100% 100%;
                transform-origin: 100% 100%;
            }

            .loader-folding-cube .loader-cube2 {
                transform: scale(1.1) rotateZ(90deg);
                transform: scale(1.1) rotateZ(90deg);
            }

            .loader-folding-cube .loader-cube3 {
                transform: scale(1.1) rotateZ(180deg);
                transform: scale(1.1) rotateZ(180deg);
            }

            .loader-folding-cube .loader-cube4 {
                transform: scale(1.1) rotateZ(270deg);
                transform: scale(1.1) rotateZ(270deg);
            }

            .loader-folding-cube .loader-cube1::before {
                background-color: var(--ion-color-primary);
                border-top-left-radius: 2px;
                filter: brightness(100%);
            }

            .loader-folding-cube .loader-cube2:before {
                animation-delay: 0.3s;
                animation-delay: 0.3s;
                background-color: var(--ion-color-primary);
                border-top-left-radius: 2px;
                filter: brightness(140%);
            }

            .loader-folding-cube .loader-cube3:before {
                animation-delay: 0.6s;
                animation-delay: 0.6s;
                background-color: var(--ion-color-primary);
                border-top-left-radius: 2px;
                filter: brightness(180%);
            }

            .loader-folding-cube .loader-cube4:before {
                animation-delay: 0.9s;
                animation-delay: 0.9s;
                background-color: var(--ion-color-primary);
                border-top-left-radius: 2px;
                filter: brightness(220%);
            }

            @keyframes loader-foldCubeAngle {

                0%,
                10% {
                    transform: perspective(140px) rotateX(-180deg);
                    transform: perspective(140px) rotateX(-180deg);
                    opacity: 0;
                }

                25%,
                75% {
                    transform: perspective(140px) rotateX(0deg);
                    transform: perspective(140px) rotateX(0deg);
                    opacity: 1;
                }

                90%,
                100% {
                    transform: perspective(140px) rotateY(180deg);
                    transform: perspective(140px) rotateY(180deg);
                    opacity: 0;
                }
            }

            @keyframes loader-foldCubeAngle {

                0%,
                10% {
                    transform: perspective(140px) rotateX(-180deg);
                    transform: perspective(140px) rotateX(-180deg);
                    opacity: 0;
                }

                25%,
                75% {
                    transform: perspective(140px) rotateX(0deg);
                    transform: perspective(140px) rotateX(0deg);
                    opacity: 1;
                }

                90%,
                100% {
                    transform: perspective(140px) rotateY(180deg);
                    transform: perspective(140px) rotateY(180deg);
                    opacity: 0;
                }
            }

            /*ion-spinner{
                margin: 10px;
            }*/
            span {
                margin-top: 30px;
                background: var(--ion-color-primary);
                padding: 20px;
                border-radius: 2px;
                color: #fff;
                filter: brightness(140%);
            }
        }
    }
}

/* pages title */
.page-title-card {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: 10px;
    background-color: var(--ion-color-primary);
    color: var(--ion-color-primary-contrast);
    padding: 10px 20px;
    margin: 20px 10px;
}

.page-title-card img {
    width: 40px;
    height: 40px;
    margin-right: 10px;
}

.page-title-card .header-icon {
    color: #fff;
    margin-right: 20px;
    font-size: 50px;
}

.page-title-card .title-data {
    display: inline-flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
}

.page-title-card .title-data div {
    font-weight: bold;
    font-size: 20px;
}

.page-title-card .title-data span {
    font-size: 14px;
    display: none;
}

// globale
ion-item:not([lines]) {
    --border-width: 0px 0px 0px 0px;
    --border-style: none;
}

ion-item:hover {
    --background-focused: none;
    --background-hover: none;
    --background: none;

    .native-input.sc-ion-input-md:autofill {
        box-shadow: 0 0 0 50px rgb(246, 246, 246) inset;
        text-fill-color: #333;
    }
}

ion-item.input-field.no-selection:hover {
    cursor: auto;
    --background: none !important;
}

.custom-div-display-file {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    ion-icon {
        cursor: pointer;
    }

    .icon-padding {
        padding-top: 20px;
    }
}

//pagina
.algoritmi-detail {
    .algoritmo-list {
        padding: 0%;
    }
}

//pagina
.algoritmi-test {
    .custom-test-item {
        border: 1px solid #d3d3d3;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;

        ion-item {
            cursor: auto;
            display: flex;
            align-items: stretch;
            padding-top: 3%;
            --inner-padding-end: 0px;

            .label-stacked.sc-ion-label-md-h {
                -webkit-transform-origin: center top;
                transform-origin: center top;
            }

            ion-label {
                display: flex;
                width: 100%;
                margin-bottom: 3%;
                // font-size: xx-small;
                // opacity: 1 !important;
            }

            .test-span {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                font-size: 40px;

                .native-input {
                    opacity: 1;
                }
            }
        }
    }
}

// pagina
.coordinate-modal {
    .preview-file {
        display: inline-flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 0 5%;

        .file-radio-item {
            display: inline-flex;
            align-items: center;
            justify-content: flex-start;

            span {
                cursor: pointer;
            }
        }

        ion-icon {
            cursor: pointer;
        }
    }
}

// pagina
.coordinate-detail {
    @media (max-width: 600px) {
        .hide-xs {
            display: none;
        }
    }

    .condition-info {
        ion-label {
            margin-bottom: 1.5%;
            font-weight: bold;
        }
    }

    .custom-col-preview {
        margin-top: 3%;

        .preview-list {
            margin-bottom: 1.5%;

            .preview-label {
                font-weight: bold;
            }

            ion-list {
                background-color: transparent;
            }

            .preview-file {
                ion-radio {
                    margin-right: 5%;
                }

                ion-button {
                    border: none !important;
                    color: black !important;
                    background-color: transparent !important;
                    --box-shadow: none;

                    ion-icon {
                        margin: 0;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}

//pssfe-selectable pssfe-dropdown
pssfe-selectable,
pssfe-dropdown {
    width: 100%;
}

//dcrud
.dcrud-input-mat-datetime-wrapper {
    mat-datepicker-toggle .mat-datepicker-toggle-default-icon {
        width: 15px !important;
        height: 15px !important;
    }
}

.dcrud-input-mat-datetime-wrapper {
    .mat-date-range-input-wrapper {
        width: calc(50% - 4px);
    }
}

.dcrud-editor-set-json-obj {
    .alert-wrapper {
        overflow-y: auto;

        textarea {
            resize: auto !important;
            white-space: pre-wrap !important;
            min-height: 500px;
            width: 100% !important;
        }
    }
}

.dcrud-nopagination-table {
    .table-footer {
        .table-footer-buttons {
            padding-left: 15px;
            padding-top: 10px;
            padding-bottom: 10px;
        }

        .table-footer-paginator {
            mat-paginator {
                display: none;
            }
        }
    }

    .table-mobile {
        .row-pagination-mobile {
            display: none;
        }
    }
}

.dcrud-sqleditor-overlay {
    .dcrud-sqleditor-keyword-blue {
        color: blue;
    }

    .dcrud-sqleditor-keyword-red {
        color: red;
    }

    .dcrud-sqleditor-keyword-green {
        color: darkgreen;

        .dcrud-sqleditor-keyword-blue,
        .dcrud-sqleditor-keyword-red {
            color: inherit;
        }
    }
}

/* scheduler */
app-admin-scheduler-card {
    width: 100%;
}

app-admin-scheduler {
    width: 100%;
    height: 100%;
}

@media only screen and (max-width: 767px) {
    app-admin-scheduler {
        overflow-y: auto;
    }
}

//pssfe-input-date-time
.pssfe-input-date-time {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    max-width: 100%;

    mat-date-range-input {
        display: inline-flex;
        align-items: center;
        justify-content: flex-start;
        width: calc(100% - 25px);

        .mat-date-range-input-container {
            align-items: center;
            justify-content: flex-start;
            display: flex;
            width: 100%;

            .mat-date-range-input-wrapper {
                width: calc(50% - 4px);
            }
        }
    }

    input {
        border: 0px;
        background: transparent;
        outline: none;
        width: calc(100% - 30px);
        max-width: calc(100% - 30px);
        min-width: calc(100% - 30px);
    }
}

/* crp custom report */
.crp-configuration-modal {
    .crp-instructions {
        width: 100%;
        --padding-bottom: 0px;
        --padding-top: 0px;
        padding: 0px;

        ion-item {
            --padding-start: 10px;
            --padding-end: 10px;
            --inner-padding-start: 0px;
            --inner-padding-end: 0px;

            ion-reorder {
                ion-icon {
                    font-size: 22px;
                }
            }

            .delete-instruction {
                font-size: 22px;
                color: var(--ion-color-danger);
            }

            .add-instruction {
                font-size: 22px;
                color: var(--ion-color-success);
            }
        }
    }

    table {
        ionic-selectable {
            height: auto !important;
            position: relative !important;

            .ionic-selectable-value {
                padding-top: 0px !important;
                padding-bottom: 0px !important;
            }

            .ionic-selectable-icon {
                display: none !important;
            }
        }
    }
}

/*fix chromium select ionic*/
ion-popover [popover]:not(:popover-open):not(dialog[open]) {
    display: contents;
}

/* table-settings-component */
.table-settings-component-drag-preview.cdk-drag-preview {
    padding: 10px 15px;
    border-bottom: 1px solid #e7e7e7;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    background-color: #fff;

    span {
        cursor: ns-resize;
    }

    .columns-list-item-buttons {
        display: inline-flex;
        align-items: center;
        justify-content: flex-end;

        ion-icon {
            font-size: 20px;
        }

        ion-icon:not(:first-child) {
            margin-left: 10px;
        }
    }
}

/* angular 16 fixes */
[hidden] {
    display: none !important;
    overflow: hidden !important;
    padding: 0 !important;
    margin: 0 !important;
}

/* ion button global */
ion-button {
    font-size: 13px;
    text-transform: capitalize;
    margin-left: 3px;
    margin-right: 3px;

    ion-label {
        line-height: 16px;
    }

    ion-spinner {
        width: 20px;
        height: 20px;
    }
}

.log-container {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    tr {
        border-bottom: 1px solid #dbdbdb;
    }

    th,
    td {
        padding: 5px;
    }
}

.mdc-notched-outline__leading,
.mdc-notched-outline__trailing {
    border: 0px !important;
}

/* mat date datetime picker*/
ngx-mat-datepicker-content,
mat-datepicker-content {
    border: 1px solid rgba(var(--ion-color-primary-rgb), 0.2);
    background: #fff;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;

    .mat-calendar-body-cell-content {
        border: 0px;
    }

    .mat-calendar-body-selected {
        background: rgba(var(--ion-color-primary-rgb), 1);
        border: 1px solid rgba(0, 0, 0, 0.2);
        color: var(--ion-color-primary-contrast);
    }

    .mat-calendar-body-active {
        color: var(--ion-color-primary-contrast);
    }

    .mat-calendar-body-disabled .mat-calendar-body-cell-content {
        background: #dadada;
        color: #fff;
    }

    .mat-calendar-table-header {
        th {
            text-align: center;
            padding: 0 0 8px;
            font-weight: normal;
            font-size: 11px;
            opacity: 0.6;
        }

        .mat-calendar-table-header-divider {
            border-top: 1px solid rgba(0, 0, 0, 0.1);
        }
    }

    .mat-calendar-body-label {
        font-size: 14px;
        font-weight: bold;
        opacity: 0.5;
    }

    .mat-calendar-body-today {
        border: 1px solid rgba(0, 0, 0, 0.2);
    }

    .mat-calendar-body-active .mat-calendar-body-today {
        background: rgba(var(--ion-color-primary-rgb), 0.2);
    }
}

/* pagine centrali */
.center-page-layout {
    display: block;
    text-align: center;
    width: 100%;

    .center-page-layout-inner {
        display: inline-block;
        max-width: 1000px;
        text-align: left;
        width: 100%;
    }
}

/* file-upload-page */
.file-upload-page {
    .dropzone {
        width: 100%;
        height: 100%;
        border-radius: 30px;
    }
}

/* select roles customers */
.select-roles {
    height: 30px;
    background-color: transparent;
    padding-left: 0px;
}

.select-roles:focus {
    outline: 0;
}

.select-customers {
    height: 30px;
    background-color: transparent;
    padding-left: 0px;
}

.select-customers:focus {
    outline: 0;
}

//chatbot
app-chat-bot {
    position: fixed;
    bottom: 0;
    right: 0;
    max-width: 100%;
    max-height: 100%;
}

@media only screen and (max-width: 576px) {
    app-chat-bot {
        .chat-bot {
            margin-right: 0px !important;
            margin-bottom: 0px !important;
            max-width: 100% !important;
        }
    }
}