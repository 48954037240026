/* modals_pops_over*/
.ald-area-detail-modal {
    &::part(content)  {
        width: 90vw !important;
        max-width: 90vw !important;
        height: 90vh !important;
    }
}
.ald-areas-import-popover{
    &::part(content)  {
        position: relative !important;
        margin: 0 auto !important;
        left: auto !important;
        top: auto !important;
        width: 90vw !important;
        max-width: 90vw !important;
        max-height: 90vh !important;
    }
}
/* infowindow maps */ 
#ald-infowindow-content{
    button{
        display: none !important;
    }
}
/* table */
.ald-table-hr {
    margin-top: 0px;
    margin-bottom: 0px;
}
/* cards */
.ald-half-card{
    display: inline-block;
    width: calc(50% - 20px);
    min-width: calc(50% - 20px);
    max-width: calc(50% - 20px);
}
@media only screen and (max-width: 764px){
    .ald-half-card{
        width: calc(50% - 20px) !important;
        min-width: calc(50% - 20px) !important;
        max-width: calc(50% - 20px) !important;
    }
}

/* tooltip */
.ald-area-type-tooltip {
    //min-width: 300px !important;
    max-width: unset !important;
    white-space: pre-line !important;
    font-size: 14px;
}

/* sdelectable */
.ald-selectable-modal{
    .ionic-selectable-item{
        ion-label{
            white-space: normal;
        }
    }
}